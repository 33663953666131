<template>
  <div>
    <h5 class="orange">Login</h5>
    <form class="row g-3" @submit.prevent="login" v-if="!forgotPassword">
      <div class="form-group col-md-4">
        <label>Your Email</label>
        <input
          type="email"
          class="form-control"
          placeholder="Username"
          v-model="loginForm.username"
          autocomplete
          required
        />
      </div>
      <div class="form-group col-md-4">
        <label>Password</label>
        <input
          type="password"
          class="form-control"
          placeholder="Password"
          v-model="loginForm.password"
          autocomplete
          required
        />
      </div>
      <div class="form-group col-md-2 offset-md-2 d-grid">
        <label>Login</label>
        <button type="submit" class="btn btn-primary">Sign in</button>
      </div>
      <hr />
      <div class="form-group col-md-2 offset-md-10 d-grid">
        <button type="button" class="btn btn-warning" @click="forgotPassword = true">
          Forgot Password?
        </button>
      </div>
    </form>
    <form class="row g-3" @submit.prevent="reset" v-else>
      <div class="form-group col-md-4">
        <label>Your Email</label>
        <input
          type="email"
          class="form-control"
          placeholder="Username"
          v-model="loginForm.username"
          autocomplete
          required
        />
      </div>

      <div class="form-group col-md-2 offset-md-6 d-grid">
        <label>Reset</label>
        <button type="submit" class="btn btn-primary">Reset Password</button>
      </div>
    </form>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import Swal from "sweetalert2";

export default {
  name: "login",
  setup() {
    const loginForm = ref({
      username: null,
      password: null,
    });
    const forgotPassword = ref(false);
    const router = useRouter();
    const store = useStore();
    onMounted(() => {
      setTimeout(() => {
        store.commit("setPreloaderStatus", false);
      }, 2000);
    });

    const login = async function () {
      store.commit("setPreloaderStatus", true);

      try {
        const auth = getAuth();
        await signInWithEmailAndPassword(
          auth,
          loginForm.value.username,
          loginForm.value.password
        );
        await store.dispatch("initiateApp");
        router.push({ name: "Home" });
        store.commit("setPreloaderStatus", false);
      } catch (error) {
        throw new Error(error);
      }
    };
    const reset = async function () {
      try {
        const auth = getAuth();
        await sendPasswordResetEmail(auth, loginForm.value.username);
        Swal.fire("Success", "Password reset was sent", "success");
        forgotPassword.value = false;
      } catch (error) {
        throw new Error(error);
      }
    };

    return { loginForm, login, reset, forgotPassword };
  },
};
</script>
